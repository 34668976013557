import React from 'react'
import HomeTwoAbout from '../components/HomeTwo/HomeTwoAbout'
import HomeTwoCounter from '../components/HomeTwo/HomeTwoCounter'
import HomeTwoFeature from '../components/HomeTwo/HomeTwoFeature'
import HomeTwoHero from '../components/HomeTwo/HomeTwoHero'
import HomeTwoProject from '../components/HomeTwo/HomeTwoProject'
import HomeTwoVideo from '../components/HomeTwo/HomeTwoVideo'
import HomeTwoWCU from '../components/HomeTwo/HomeTwoWCU'
import HomeTwoWorkingProcess from '../components/HomeTwo/HomeTwoWorkingProcess'
import Layout1 from '../layouts/Layout1'
import {Helmet} from "react-helmet";
import CardSlider from '../components/ServiceDetails/NewStyleTestimonails'


export default function Home2() {
    return (
        <>
           <Helmet>
           <title>Custom Embroidery Digitizing | Vector Art Services | Silk Digitizing</title> 
           <meta name="description" content="Custom Embroidery Digitizing - Silk digitizing offers affordable embroidery digitizing with real sew out and vector arts services at low price."></meta>
        <link rel="canonical" href="https://www.silkdigitizing.com"></link>
           </Helmet>
            <Layout1>
                <HomeTwoHero/>
                <HomeTwoAbout/>
                <HomeTwoFeature/>
                <HomeTwoVideo/>
                <HomeTwoWCU/>
                <HomeTwoWorkingProcess/>
                <CardSlider/>
                <HomeTwoCounter/>
                <HomeTwoProject/>
                
                {/* <HomeTwoNews/> */}
            </Layout1>
        </>
    )
}
