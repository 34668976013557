import React, { useState } from 'react';
import axios from 'axios';
import VideoPopUp from '../Plugins/VideoPopup';

export default function HomeTwoVideo() {
  const [isPopUp, setPopUp] = useState(false);
  // State Management
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState(localStorage.getItem('services') || "Select a service");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [encodedFile, setEncodedFile] = useState(""); // State for Base64 file data

  // Maximum file size in MB
  const MAX_FILE_SIZE_MB = 10;

  // Convert file to Base64 and validate size
  const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
          const validFormats = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
          if (!validFormats.includes(selectedFile.type)) {
              alert('Only PNG, JPG, JPEG, and PDF formats are allowed.');
              e.target.value = ""; // Clear the input field
              return;
          }

          // Check file size (binary size, not Base64 size)
          const maxFileSizeInBytes = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes
          if (selectedFile.size > maxFileSizeInBytes) {
              alert(`File size should not exceed ${MAX_FILE_SIZE_MB}MB.`);
              e.target.value = ""; // Clear the input field
              return;
          }

          setFile(selectedFile);

          // Read file as Base64
          const reader = new FileReader();
          reader.onload = () => {
              setEncodedFile(reader.result.split(",")[1]); // Exclude metadata
          };
          reader.readAsDataURL(selectedFile);
      }
  };

  // Not All Form JS in use
  // API call function
  const handleSubmit = async (e) => {
      e.preventDefault();

      const payload = {
          name,
          email,
          phone,
          subject,
          message,
          file: encodedFile, // Add Base64 encoded file
          filename: file?.name || "", // Optionally include the file name
          fileType: file?.type || "" // Optionally include the file MIME type
      };

      try {
          const response = await axios.post(
              'https://www.silkdigitizing.com/cp/contact_action.asp',
              payload,
              {
                  headers: {
                      'Content-Type': 'application/json',
                      Accept: "application/json",
                  }
              }
          );

          console.log("API Response:", response.data);
          if (response.data.status === "success") {
              alert(response.data.message); // Show success alert

              // Reset form fields
              setName("");
              setEmail("");
              setPhone("");
              setSubject("Select a service");
              setMessage("");
              setFile(null);
              setEncodedFile("");
              localStorage.removeItem('services');
          }
      } catch (err) {
          console.error("Error:", err);
          alert("Message sending failed, please try again.");
      }
  };

  return (
    <>
      
      {/* Video area start */}
      <section className="video-area">
        <div className="container-fluid">
          <div className="row no-gutters">
            <div className="col-xl-8">
              <div className="video__bg" style={{ position: 'relative', height: '100%', width: '100%' }}>
                <img
                  src="/images/bg/embroidery-machine.webp"
                  alt="Embroidery Machine"
                  loading="lazy"
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: -1,
                    border: "1px solid transparent",
                    borderRadius: "2px",

                  }}
                />
                <div className="video-container-wrap">
                  <a
                    onClick={() => setPopUp(true)}
                    className="video-link pointer"
                    data-rel="lightcase:myCollection"
                    data-animation="fadeInLeft"
                    data-delay=".1s"
                  >
                    <div className="video-play-wrap">
                      <div className="video-mark">
                        <div className="wave-pulse wave-pulse-1"></div>
                        <div className="wave-pulse wave-pulse-2"></div>
                      </div>
                      <div className="video-play">
                        <i className="fa fa-play"></i>
                      </div>
                    </div>
                  </a>
                  <a
                    onClick={() => setPopUp(true)}
                    className="video-text pointer"
                    data-rel="lightcase:myCollection"
                    data-animation="fadeInLeft"
                    data-delay=".1s"
                  >
                    Play Video
                  </a>
                </div>
              </div>
              {isPopUp && (
                <VideoPopUp
                  setShow={() => setPopUp(false)}
                  src="/images/About-Us/Aboutpagevideo.mp4"
                  preload="none"
                />
              )}
            </div>

            <div className="col-xl-4">

            <div className="quote-wrapper">
                                <h2 className="quote-title">Free quote</h2>



                                <div className="quote-form">
                                    <form method="POST"
                                        action="https://www.silkdigitizing.com/cp/contact_action.asp" enctype="multipart/form-data" className="mt-none-15">
                                        <div className="form-group mt-15" >
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder="Contact Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mt-15">
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder="Contact Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mt-15" id="contact">
                                            <input
                                                type="tel"
                                                name="phone"
                                                placeholder="Contact Number"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mt-15">
                                            <select
                                                name="subject"
                                                value={subject}
                                                onChange={(e) => setSubject(e.target.value)}
                                                style={{ width: '100%' }}
                                                required
                                                aria-label="selector option"
                                            >
                                                <option value=" " disabled>
                                                    Choose Service
                                                </option>
                                                <option value="Embroidery Digitizing">Embroidery Digitizing</option>
                                                <option value="Vector Conversion">Vector Conversion</option>
                                                <option value="Custom Patches">Custom Patches</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-15">
                                            <input
                                                type="file"
                                                name="attachment"
                                                onChange={handleFileChange}
                                                accept=".png, .jpg, .jpeg, .pdf"
                                            />
                                            <small style={{ display: 'block', color: '#fff', marginTop: '5px', textAlign:'right' }}>
                                                Max file size: {MAX_FILE_SIZE_MB}MB
                                            </small>
                                        </div>
                                        <div className="form-group mt-15">
                                            <textarea
                                                name="message"
                                                placeholder="Your Message"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                required
                                            ></textarea>
                                        </div>
                                        <div className="form-group mt-15">
                                            <button type="submit" className="quote-btn" aria-label="For Submission">
                                                <span className="icon">
                                                    <i className="far fa-arrow-right"></i>
                                                </span>
                                                Request Quote
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
            </div>
          </div>
        </div>
      </section>
      {/* Video area end */}
    </>
  );
}
