import React, { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import patchesDetailData from "./patchesDetailData";
import { Helmet } from 'react-helmet';
import ServiceRecPortfolio from './ServiceRecPortfolio';

export default function PatchesDetailsContent() {
    const { pathname } = useLocation();
    const { name } = useParams();
    const sameName = pathname === "/custom-patches" ? "custom-patches" : name;
    const FilterData = patchesDetailData && patchesDetailData.filter((item) => item.slug === sameName);

    // Scroll to the content div when pathname or FilterData changes
    useEffect(() => {
        if (window.innerWidth <= 768) { // For tablet and mobile screens
            const targetElement = document.getElementById("toview");
            if (targetElement) {
                // Delay scrolling until the DOM is fully updated
                setTimeout(() => {
                    targetElement.scrollIntoView({ behavior: "smooth" });
                }, 100); // Small delay to ensure content is rendered
            }
        }
    }, [pathname, FilterData]); // Trigger whenever pathname or FilterData changes

    return (
        <>
            <Helmet>
                {name ? (
                    <link rel="canonical" href={`https://www.silkdigitizing.com/custom-patches/${name}`} />
                ) : (
                    <link rel="canonical" href={`https://www.silkdigitizing.com/custom-patches`} />
                )}
            </Helmet>
            {/* <!-- details content start --> */}
            <div className="details-content-area pt-100 pb-100 cps1">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="sidebar grey-bg">
                                <h2 className="sidebar__title">
                                    <span><img src="/images/shape/heading-shape-3.png" className="mr-5" alt="" width="14" height="14" /></span>
                                    Custom Patches
                                </h2>
                                <ul className="sidebar__list">
                                    <li>
                                        <Link to="/custom-patches">
                                            Custom Patches
                                            <span className="icon"><i className="far fa-arrow-right"></i></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/custom-patches/embroidered-patches">
                                            Embroidered Patches
                                            <span className="icon"><i className="far fa-arrow-right"></i></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/custom-patches/chenille-patches">
                                            Chenille Patches
                                            <span className="icon"><i className="far fa-arrow-right"></i></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/custom-patches/woven-patches">
                                            Woven Patches
                                            <span className="icon"><i className="far fa-arrow-right"></i></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/custom-patches/pvc-patches">
                                            PVC Patches
                                            <span className="icon"><i className="far fa-arrow-right"></i></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/custom-patches/printed-patches">
                                            Printed Patches
                                            <span className="icon"><i className="far fa-arrow-right"></i></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/custom-patches/leather-patches">
                                            Leather Patches
                                            <span className="icon"><i className="far fa-arrow-right"></i></span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            {FilterData && FilterData.map(({ id, name, content, booking, design, order, image, portfolioImage }) => {
                                return (
                                    <div className="details-content" key={id + name}>
                                        <div className="details-content__thumb mb-30" id="toview">
                                            <img src={image} alt={name} width="856" height="402" />
                                        </div>
                                        <h2 className="details-content__title mb-20">{name}</h2>
                                        <p>{content}</p>
                                        <div className="content mt-20">
                                            <h3 className="details-content__title mt-30 mb-25">Working Process</h3>
                                        </div>
                                        <div className="row mt-none-30">
                                            <div className="col-xl-4 col-lg-6 col-md-6 mt-30">
                                                <div className="wp-box wp-box__2 text-center">
                                                    <div className="wp-box__icon wp-box__icon--center wp-box__icon--2 mb-25">
                                                        <img src="/images/icons/w-p-1.png" alt="" width="50" height="50" />
                                                    </div>
                                                    <div className="wp-box__content">
                                                        <h4 className="wp-box__title wp-box__title--2">Booking Online</h4>
                                                        <p>{booking}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 mt-30">
                                                <div className="wp-box wp-box__2 text-center">
                                                    <div className="wp-box__icon wp-box__icon--center wp-box__icon--3 mb-25">
                                                        <img src="/images/icons/w-p-3.png" alt="" width="64" height="46" />
                                                    </div>
                                                    <div className="wp-box__content">
                                                        <h4 className="wp-box__title wp-box__title--2">Approve Design</h4>
                                                        <p>{design}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 mt-30">
                                                <div className="wp-box wp-box__2 text-center">
                                                    <div className="wp-box__icon wp-box__icon--center wp-box__icon--4 mb-25">
                                                        <img src="/images/icons/w-p-4.png" alt="" width="55" height="44"  />
                                                    </div>
                                                    <div className="wp-box__content">
                                                        <h4 className="wp-box__title wp-box__title--2">Start Production</h4>
                                                        <p>{order}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ServiceRecPortfolio portfolioImage={portfolioImage} name={name} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- details content end --> */}
        </>
    );
}
