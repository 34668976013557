import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

const testimonials = [
  { text: "I always get what I order. They are always helpful, on time, very fast service. Never disappointed or regret on their work. Thank you for being my right hand.", author: "Ruth Tesfai" },
  { text: "Have been doing a great job for us for several years now. Job gets back under 24 hrs and edits are taken care of right away.", author: "Scott C" },
  { text: "I wanna say I received the best service for my purchase, quick shipping, great condition, great quality, and on time!!!! I would advise others to stick with this company after seeing myself they're great work ethics!!!", author: "Quashon Winchester" },
  { text: "Love my chenille patches definitely gonna be ordering more soon. Customer service was awesome and shipping was fast.", author: "ENY Nitty" },
  { text: "Tried to get this done but I got a lot of excuses from other companies. They were able to get everything together quick and the patches came out amazing!!", author: "LycanNerd" },
  { text: "Amazing work!!! Shipping is fast, good condition product!!! Love it will be doing more work!!", author: "Abdul Ayaan" },
  { text: "Awesome company. Do great work, quick and redo changes fast if needed.. recommend them highly.", author: "Lori Cervi" },
  { text: "Great work, fast service. My go to every time, you won’t regret this investment!", author: "Juwann Wadley" },
  { text: "Amazing! They do what u want make wonderful suggestions they are ready quickly", author: "Gina" },
  { text: "10 out of 10! look forward to future business! Thank you", author: "Official OnTheRon" },
  { text: "Very professional and fast delivery", author: "Twintwo Johnson" },
  { text: "Quick, honest and reasonable prices.", author: "Mike Courtois" },
  { text: "My designs always come back and sew very well.", author: "Diana Banta" },
  { text: "Amazing digitizers. Always great job.", author: "Judy" },
  { text: "Fast turn around and great design.", author: "John Paul" },
  { text: "Dope work💪🏿💪🏿💪🏿💪🏿", author: "Big Dodds' Custom" },
  { text: "Absolutely the best!!!", author: "Cee The man" },
  { text: "Fast, dependable service. Highly recommend.", author: "Pat Beauregard" },
];

const CardSlider = () => {
  return (
    <div className="section-header">
    <h2 className="section-title section-title__2 text-center mt-10">CLIENT TESTIMONIALS </h2>
    <div style={styles.sliderContainer}>
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        spaceBetween={20}
        centeredSlides={true}
        slidesPerView="auto"
        style={styles.swiper}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index} style={styles.card}>
            <div style={styles.cardContent}>
              <img src="/images/other/Google.webp" alt="Google thumb" style={styles.googleThumb} />
              <p style={styles.testimonialText}>"{testimonial.text}"</p>
              <p style={styles.author}>— {testimonial.author}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    </div>
  );
};

const styles = {
  sliderContainer: {
    width: '100%',
    height:'100%',
    maxWidth: '1920px',
    maxHeight: '396px',
    margin: '50px auto',
    backgroundImage: 'url("/images/Services/Embroidery Digitizing/bg-testimonials.webp")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  swiper: {
    padding: '20px 0',
  },
  card: {
    width: '306px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // height: '510px',
    height: '340px',
    // height: '285px',
    borderRadius: '15px',
    backgroundColor: '#fff',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
    transition: 'transform 0.3s ease',
    padding: '20px',
    scrollSnapAlign: 'center', // Ensures card snaps to center
  },
  cardContent: {
    textAlign: 'center',
    color: '#000',
  },
  googleThumb: {
    width: '35px',
    height: '35px',
    marginBottom: '10px',
  },
  testimonialText: {
    fontSize: '1rem',
    lineHeight: '1.5',
    marginBottom: '10px',
  },
  author: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
};

export default CardSlider;
