import React from 'react'
import { Link } from 'react-router-dom'

export default function HomeTwoHero() {
    return (
        <>
        
            {/* <!-- hero start --> */}
            <section className="hero__2 bg_img hero-cover" >
                <div className="container-fluid">
                    <div className="row justify-content-end">
                        <div className="col-xl-5 custom-col-width justify-content-end col-lg-7">
                            <div className="hero__content hero__content--2 text-center">
                                <div className="hero-icon hero-cards">
                                    <img src="/images/icons/hero-icon.webp" alt="" width={116} height={100} />
                                </div>
                                <div className="hero-text">
                                    <h1>Digitizing <br />
                                        Made Easier</h1>
                                    <p>Your priority is ours</p>
                                    <Link to="/contact#contact" className="fq site-btn site-btn__2" aria-label='Navigate to About Page'><span className="icon">
                                        <i
                                            className="far fa-arrow-right"></i></span>Free Quote</Link>
                                </div>
                                <div className="dot-shape"><img src="/images/shape/hero-pattern-2.webp" alt="" width={599} height={620} style={{ maxWidth: '100%' }} /></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="shape-pattern">
                    <img src="/images/shape/hero-pattern-1.webp" alt="" width={361} height={661} style={{ maxWidth: '100%' }} />
                </div> */}
            </section>
            {/* <!-- hero end -->    */}
        </>
    )
}
