import React from 'react'
import { Link } from 'react-router-dom'

export default function PricingCard() {
    return (
        <>
            {/* <!-- pricing section start --> */}
            <section className="pricing-area pricing-area__2 pt-70 pb-70 cps3">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <div className="section-header mb-80">
                                <h4 className="sub-heading sub-heading__2 mb-25">
                                    <span><img src="/images/shape/heading-shape-3.png" className="mr-10" alt="" width="14" height="14"  /></span>
                                    affordable price
                                    <span><img src="/images/shape/heading-shape-4.png" className="ml-10" alt="" width="14" height="14"  /></span>
                                </h4>
                                <h2 className="section-title section-title__2">Pricing Package</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-none-30 justify-content-center">
                        <div className="col-xl-4 col-lg-6 mt-30">
                            <div className="pricing-item">
                                                            <div className="pricing-item__head">
                                                                <h2 className="heading-title">Custom Patches</h2>
                                                                {/* <h4 className="sub-heading-title"></h4> */}
                                                            </div>
                                                            <ul className="pricing-item__lists">
                                                                <li>Price of Custom Patches depends</li>
                                                                <li> on various factors</li>
                                                                <li>Type of Patch</li>
                                                                <li>Size of Patch</li>
                                                                <li>Complexity of design</li>
                                                                {/* <li>Free Quote</li>
                                                                <li>Free Digital Samples</li> */}
                                                                {/* <select className='pricing-selected'>
                                                                    <option className='selected'>Select Threads</option>
                                                                    <option>Staple</option>
                                                                    <option>Polyester</option>
                                                                    <option>Chenille</option>
                                                                </select> */}
                                                                
                                                            </ul>
                                                            <Link to="/contact#contact" className="price-btn">
                                                                <span className="icon"><i className="far fa-arrow-right"></i></span>
                                                               Request Quote
                                                           </Link>
                                                        </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- pricing section end -->    */}
        </>
    )
}
