import React from 'react';
import { useState } from 'react';
import NewStyleTestimonials from './NewStyleTestimonails';
import axios from 'axios';
import EmbDigitizingLast from './EmbDigitizingComponent';
import ServicesDetailsContent2 from './ServicesDetailsContent2';
import ContactBanner from './ContactBanner';
import WorkingProcessContent from './WorkingProcessContent';
import EDBreadcrumb from './EmbroideryDigitBreadCrumb';
// import QuoteButtonWithHover from './FreeQuoteButton';
import ViewPortfolioBtn from './ViewPortfolioBtn';



export default function EmbroideryDigitizing() {
    // State Management
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState(localStorage.getItem('services') || "Select a service");
    const [message, setMessage] = useState("");
    const [file, setFile] = useState(null);
    const [encodedFile, setEncodedFile] = useState(""); // State for Base64 file data

    // Maximum file size in MB
    const MAX_FILE_SIZE_MB = 10;

    // Convert file to Base64 and validate size
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const validFormats = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
            if (!validFormats.includes(selectedFile.type)) {
                alert('Only PNG, JPG, JPEG, and PDF formats are allowed.');
                e.target.value = ""; // Clear the input field
                return;
            }

            // Check file size (binary size, not Base64 size)
            const maxFileSizeInBytes = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes
            if (selectedFile.size > maxFileSizeInBytes) {
                alert(`File size should not exceed ${MAX_FILE_SIZE_MB}MB.`);
                e.target.value = ""; // Clear the input field
                return;
            }

            setFile(selectedFile);

            // Read file as Base64
            const reader = new FileReader();
            reader.onload = () => {
                setEncodedFile(reader.result.split(",")[1]); // Exclude metadata
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    // API call function
    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            name,
            email,
            phone,
            subject,
            message,
            file: encodedFile, // Add Base64 encoded file
            filename: file?.name || "", // Optionally include the file name
            fileType: file?.type || "" // Optionally include the file MIME type
        };

        try {
            const response = await axios.post(
                'https://www.silkdigitizing.com/cp/contact_action.asp',
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: "application/json",
                    }
                }
            );

            console.log("API Response:", response.data);
            if (response.data.status === "success") {
                alert(response.data.message); // Show success alert

                // Reset form fields
                setName("");
                setEmail("");
                setPhone("");
                setSubject("Select a service");
                setMessage("");
                setFile(null);
                setEncodedFile("");
                localStorage.removeItem('services');
            }
        } catch (err) {
            console.error("Error:", err);
            alert("Message sending failed, please try again.");
        }
    };

    return (
        <>

            <EDBreadcrumb />
            {/* <!-- details content start --> */}
            <div className="details-content-area pt-75 pb-45 eds1" >
                <div className="container">
                    <div className="row">
                    <div className="col-xl-8">

<div className="details-content" >



    <h2 className="details-content__title mb-20">Embroidery Digitizing Service</h2>
    <p>Welcome to the home of top-notch embroidery digitizing! We specialize in transforming your artwork,
        logos, and designs into high-quality embroidery files that deliver outstanding results on any fabric.
    </p>
    <div className='strong'>
        <ul class="mt-20 mb-20 ">
            <li className='pricing-sd'>
                Pricing
            </li>

            <li>Text Name (Left Chest / Cap) - $10</li>
            <li>Left Chest / Cap Design (under 10k stitches) - $15</li>
            <li>Medium Size Design - $20</li>
            <li>Jacket Front (under 40k stitches) Min - $30</li>
            <li>Jacket (up to 100k stitches) - $75</li>

        </ul>
    </div>
    <div className='mbc'>
        {/* <QuoteButtonWithHover /> */}
    </div>

</div>

</div>
                        <div className="col-xl-4">
                            <div className="sidebar  mt-0 dark-purple">
                                <h2 className="sidebar__title text-white">

                                    <span><img src="/images/shape/heading-shape-3.png" className="mr-5" alt="" width="14" height="14" /></span>
                                    Quick Inquiry
                                </h2>
                                <div className="contact-form contact-form__3">
                                    <form method="POST"
                                        action="https://www.silkdigitizing.com/cp/contact_action.asp" enctype="multipart/form-data" >
                                        <div className="form-group mt-20">
                                            <input type="text"
                                                name="name"
                                                placeholder="Contact Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required />
                                        </div>
                                        <div className="form-group mt-20">
                                            <input type="email"
                                                name="email"
                                                placeholder="Contact Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required />
                                        </div>
                                        <div className="form-group mt-20">
                                            <input type="tel"
                                                name="phone"
                                                placeholder="Contact Number"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                required />
                                        </div>
                                        <div className="form-group mt-20">
                                            <select
                                                aria-label="Select One Option"
                                                name="subject"
                                                value={subject}
                                                onChange={(e) => setSubject(e.target.value)}

                                                required

                                                style={{ width: '100%', background: "#fff", borderRadius: "0", border: "none" }}
                                            >
                                                <option value="" disabled>Choose Service</option>
                                                <option value="Embroidery Digitizing">Embroidery Digitizing</option>
                                                <option value="Vector Conversion">Vector Conversion</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-20"><input type="file"
                                            name="attachment"
                                            onChange={handleFileChange}
                                            accept=".png, .jpg, .jpeg, .pdf" />

                                            <small style={{ display: 'block', color: '#fff', marginTop: '5px', textAlign: 'right' }}>
                                                Max file size: {MAX_FILE_SIZE_MB}MB
                                            </small>
                                        </div>
                                        <div className="form-group mt-20">
                                            <textarea name="message"
                                                placeholder="Your Message"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                required></textarea>
                                        </div>
                                        <div className='mbc'>
                                            <button type="submit" className="site-btn site-btn__2 mt-10 "><span
                                                className="icon icon__black"><i className="far fa-arrow-right"></i></span>Get Free Quote
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        


                        <div className='col-xl-12'>
                            <div className="details-content" >
                                <div className=" project-area project-area__2 pt-80 pb-80 eds2">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-xl-8 text-center">
                                                <div className="section-header mb-65">
                                                    <h3 className="sub-heading sub-heading__2 mb-15">
                                                        <span><img src="/images/shape/heading-shape-3.png" className="mr-5" alt="" width="14" height="14" /></span>
                                                        Project showcase
                                                        <span><img src="/images/shape/heading-shape-4.png" className="ml-5" alt="" width="14" height="14" /></span>
                                                    </h3>
                                                    <h3 className="section-title section-title__2">Recent Projects</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid">
                                        <div className="row project-row mt-none-30">
                                            {/* Image One */}
                                            <div className="col-md-6 mt-30" >
                                                <div className="project-item">
                                                    <div className="project-item__thumb">
                                                        <img src="../../images/Portfolio/EmbroideryDigitizing/FlatEmbroideryDigitizing/nitty-logo.webp" alt="" width="612" height="284" />
                                                    </div>
                                                    <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                                        <div className="project-item__content">
                                                            {/* <h5 className="project-item__subtitle"><span>//</span> Book Print</h5> */}
                                                            <h4 className="project-item__title">Embroidery Digitizing</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Image Two */}
                                            <div className="col-md-6 mt-30" >
                                                <div className="project-item">
                                                    <div className="project-item__thumb">
                                                        <img src="../../images/Portfolio/EmbroideryDigitizing/FlatEmbroideryDigitizing/GMK-logo.webp" alt="" width="612" height="401" />
                                                    </div>
                                                    <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                                        <div className="project-item__content">
                                                            {/* <h5 className="project-item__subtitle"><span>//</span> Book Print</h5> */}
                                                            <h4 className="project-item__title">Embroidery Digitizing</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Image Three */}
                                            <div className="col-md-6 mt-30" >
                                                <div className="project-item">
                                                    <div className="project-item__thumb">
                                                        <img src="../../images/Portfolio/EmbroideryDigitizing/FlatEmbroideryDigitizing/RACK-RACER-DESIGN.webp" alt="" width="612" height="372" />
                                                    </div>
                                                    <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                                        <div className="project-item__content">
                                                            {/* <h5 className="project-item__subtitle"><span>//</span> Book Print</h5> */}
                                                            <h4 className="project-item__title">Embroidery Digitizing</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Image Four */}
                                            <div className="col-md-6 mt-30" >
                                                <div className="project-item">
                                                    <div className="project-item__thumb">
                                                        <img src="../../images/Portfolio/EmbroideryDigitizing/FlatEmbroideryDigitizing/Gogi-logo.webp" alt="" width="612" height="397" />
                                                    </div>
                                                    <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                                        <div className="project-item__content">
                                                            {/* <h5 className="project-item__subtitle"><span>//</span> Book Print</h5> */}
                                                            <h4 className="project-item__title">Embroidery Digitizing</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        <center className='mt-40'>
                                            <ViewPortfolioBtn />
                                        </center>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div>
                <NewStyleTestimonials />
            </div>
            <EmbDigitizingLast />
            <ServicesDetailsContent2 />
            <WorkingProcessContent />
            <ContactBanner />
            {/* <!-- details content end -->    */}
        </>
    )
}
