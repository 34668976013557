import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

export default function ContactGTA() {
    const location = useLocation();

    

    // Smooth scrolling function on component load
    useEffect(() => {
        if (location.hash === "#contact") {
            setTimeout(() => {
                const formSection = document.getElementById("contact");
                if (formSection) {
                    formSection.scrollIntoView({ behavior: "smooth" });
                }
            }, 100);
        }
    }, [location]);

    // State Management
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState(localStorage.getItem('services') || "Select a service");
    const [message, setMessage] = useState("");
    const [file, setFile] = useState(null);
    const [encodedFile, setEncodedFile] = useState(""); // State for Base64 file data

    // // Maximum file size in MB
    const MAX_FILE_SIZE_MB = 10;

    // // Convert file to Base64 and validate size
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const validFormats = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
            if (!validFormats.includes(selectedFile.type)) {
                alert('Only PNG, JPG, JPEG, and PDF formats are allowed.');
                e.target.value = ""; // Clear the input field
                return;
            }

            // Check file size (binary size, not Base64 size)
            const maxFileSizeInBytes = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes
            if (selectedFile.size > maxFileSizeInBytes) {
                alert(`File size should not exceed ${MAX_FILE_SIZE_MB}MB.`);
                e.target.value = ""; // Clear the input field
                return;
            }

            setFile(selectedFile);

            // Read file as Base64
            const reader = new FileReader();
            reader.onload = () => {
                setEncodedFile(reader.result.split(",")[1]); // Exclude metadata
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    // // Not All Form JS in use
    // // API call function
    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            name,
            email,
            phone,
            subject,
            message,
            file: encodedFile, // Add Base64 encoded file
            filename: file?.name || "", // Optionally include the file name
            fileType: file?.type || "" // Optionally include the file MIME type
        };

        try {
            const response = await axios.post(
                'https://www.silkdigitizing.com/cp/contact_action.asp',
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: "application/json",
                    }
                }
            );

            console.log("API Response:", response.data);
            if (response.data.status === "success") {
                alert(response.data.message); // Show success alert

                // Reset form fields
                setName("");
                setEmail("");
                setPhone("");
                setSubject("Select a service");
                setMessage("");
                setFile(null);
                setEncodedFile("");
                localStorage.removeItem('services');
            }
        } catch (err) {
            console.error("Error:", err);
            alert("Message sending failed, please try again.");
        }
    };

    return (
        <>
            <section className="gta-area gta-area__2 pt-100 pb-100 cus1">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            {/* <div className="gta-bg__2">
                                <img src="/images/Contact-Us/824x565 (2).png" alt="" width="700" height="480" />
                            </div> */}

                            <div className="contact-info">
                                <h4 className="contact-info__title">Silk Digitizing</h4><br></br>
                                <p>2918 Avenue I #1003 <br></br>Brooklyn, NY 11210<br></br> United States</p>
                               
                                <FaEnvelope style={{ color: '#0C1523', marginRight: '10px' }} />                                    
                                <Link to="mailto:info@silkdigitizing.com">info@silkdigitizing.com</Link>
                                <div></div>
                                <FaPhoneAlt style={{ color: '#0C1523', marginRight: '10px' }} />
                                <Link to="tel:+1929-279-8016">(929) 279-8016</Link>
                            </div>
                        </div>
                        <div className="col-xl-6 pl-50">
                            {/* <div className="section-header mb-50">
                                <h4 className="sub-heading sub-heading__2 mb-15">Send Message
                                    <span>
                                        <img src="/images/shape/heading-shape-4.png" className="mr-10 mx-2" alt="" width="14" height="14" />
                                    </span>
                                </h4>
                                <h2 className="section-title section-title__2 " id="contact">Get in Touch</h2>
                            </div> */}
                            {/* <div className="contact-form">
                                <form onSubmit={handleSubmit} id="contact-form">
                                    <div className="form-group mt-25">
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Contact Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group mt-25">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Contact Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group mt-25">
                                        <input
                                            type="tel"
                                            name="phone"
                                            placeholder="Contact Number"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group mt-25">
                                        <select
                                            name="subject"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            style={{ width: '100%' }}
                                            required
                                        >
                                            <option value="" disabled>Select a service</option>
                                            <option value="Embroidery Digitizing">Embroidery Digitizing</option>
                                            <option value="Vector Conversion">Vector Conversion</option>
                                            <option value="Custom Patches">Custom Patches</option>
                                        </select>
                                    </div>
                                    <div className="form-group mt-25">
                                        <textarea
                                            name="message"
                                            placeholder="Your Message"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="form-group mt-25">
                                        <input
                                            type="file"
                                            name="attachment"
                                            onChange={handleFileChange}
                                            accept=".png, .jpg, .jpeg, .pdf"
                                        />
                                        <small style={{ display: 'block', color: '#888', marginTop: '5px' }}>
                                            Max file size: {MAX_FILE_SIZE_MB}MB
                                        </small>
                                    </div>
                                    <button type="submit" className="site-btn site-btn__2 mt-15">
                                        <span className="icon icon__black">
                                            <i className="far fa-arrow-right"></i>
                                        </span>
                                        Contact us
                                    </button>
                                </form>
                            </div> */}

                            <div className="quote-wrapper messageus">
                                <h2 className="quote-title">Free quote</h2>



                                <div className="quote-form" id="contact">
                                    <form method="POST"
                                        action="https://www.silkdigitizing.com/cp/contact_action.asp" className="mt-none-15">
                                        <div className="form-group mt-15" >
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder="Contact Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mt-15">
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder="Contact Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mt-15" >
                                            <input
                                                type="tel"
                                                name="phone"
                                                placeholder="Contact Number"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mt-15">
                                            <select
                                                name="subject"
                                                value={subject}
                                                onChange={(e) => setSubject(e.target.value)}
                                                style={{ width: '100%' }}
                                                required
                                                aria-label="selector option"
                                            >
                                                <option value=" " disabled>
                                                    Choose Service
                                                </option>
                                                <option value="Embroidery Digitizing">Embroidery Digitizing</option>
                                                <option value="Vector Conversion">Vector Conversion</option>
                                                <option value="Custom Patches">Custom Patches</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-15">
                                            <input
                                                type="file"
                                                name="attachment"
                                                onChange={handleFileChange}
                                                accept=".png, .jpg, .jpeg, .pdf"
                                            />
                                            <small style={{ display: 'block', color: '#fff', marginTop: '5px', textAlign: 'right' }}>
                                                Max file size: {MAX_FILE_SIZE_MB}MB
                                            </small>
                                        </div>
                                        <div className="form-group mt-15">
                                            <textarea
                                                name="message"
                                                placeholder="Your Message"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                required
                                            ></textarea>
                                        </div>
                                        <div className="form-group mt-15">
                                            <button type="submit" className="quote-btn" aria-label="For Submission">
                                                <span className="icon">
                                                    <i className="far fa-arrow-right"></i>
                                                </span>
                                                Request Quote
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
