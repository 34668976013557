import React from 'react'
import { Link } from 'react-router-dom'

export default function HomeTwoProject() {
    return (
        <>
            {/* <!-- project section start --> */}
            <div className="pt-75 project-area project-area__2">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <div className="section-header mb-65">
                                <h2 className="sub-heading sub-heading__2 mb-15">
                                    <span><img src="/images/shape/heading-shape-3.avif" className="mr-5" alt="" width={14} height={14} style={{maxWidth: '100%'}}/></span>
                                    Project showcase
                                    <span><img src="/images/shape/heading-shape-4.avif" className="ml-5" alt="" width={14} height={14} style={{maxWidth: '100%'}}/></span>
                                </h2>
                                <h3 className="section-title section-title__2">Recent projects</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row project-row mt-none-30">
                        <div className="col-xl-5 col-lg-6 col-md-6 d-flex align-self-stretch mt-30">
                        <div className="row mt-none-30">
                        <div className="col-xl-12 col-md-6 col-lg-12 mt-30">
                            <div className="project-item">
                                        <div className="project-item__thumb">
                                            <img loading="lazy" src="/images/Portfolio/LineArt/art-swagg.webp" alt="" width={576} height={504} style={{maxWidth: '100%'}}/>
                                        </div>
                                        <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                            <Link to="/services/line-art" className="project-item__link" aria-label='Navigate to Line Art Page'><i
                                                className="far fa-arrow-right"></i></Link>
                                            <div className="project-item__content">
                                                {/* <h5 className="project-item__subtitle"><span>//</span> Book Print</h5> */}
                                                <h4 className="project-item__title">Line Art</h4>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className="col-xl-12 col-md-6 col-lg-12 mt-30">
                            <div className="project-item">
                                        <div className="project-item__thumb">
                                            <img loading="lazy" src="/images/Portfolio/VectorConversion/cougar-head-vector-logo.webp" alt="" width={576} height={533} style={{maxWidth: '100%'}}/>
                                        </div>
                                        <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                            <Link to="/services/vector-conversion" className="project-item__link" aria-label='Navigate to Vector Conversion Page'><i
                                                className="far fa-arrow-right"></i></Link>
                                            <div className="project-item__content">
                                                {/* <h5 className="project-item__subtitle"><span>//</span> Book Print</h5> */}
                                                <h4 className="project-item__title">Vector Conversion</h4>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-7 mt-30">
                            <div className="row mt-none-30">
                                <div className="col-xl-6 col-md-6 col-lg-6 mt-30">
                                    <div className="project-item">
                                        <div className="project-item__thumb">
                                            <img loading="lazy" src="/images/Portfolio/Graphic Designing/Logo-Designing/BS-society-logo.webp" alt="" width={396} height={480} style={{maxWidth: '100%'}}/>
                                        </div>
                                        <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                            <Link to="/services/graphic-designing/logo-designing" className="project-item__link" aria-label='Navigate to Logo Designing Page'><i
                                                className="far fa-arrow-right"></i></Link>
                                            <div className="project-item__content">
                                                {/* <h5 className="project-item__subtitle"><span>//</span> Book Print</h5> */}
                                                <h4 className="project-item__title">Logo Designing</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6 col-lg-6 mt-30">
                                    <div className="project-item">
                                        <div className="project-item__thumb">
                                            <img loading="lazy" src="/images/Portfolio/Graphic Designing/Photo-Editing/statue-of-liberty.webp" alt="" width={396} height={505} style={{maxWidth: '100%'}}/>
                                        </div>
                                        <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                            <Link to="/services/graphic-designing/photo-editing" className="project-item__link" aria-label='Navigate to Photo Editing Page'><i
                                                className="far fa-arrow-right"></i></Link>
                                            <div className="project-item__content">
                                                {/* <h5 className="project-item__subtitle"><span>//</span> Book Print</h5> */}
                                                <h4 className="project-item__title">Photo Editing</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-md-6 col-lg-12 col-md-12 mt-30">
                                <div className="project-item">
                                <div className="project-item__thumb project-item__thumb--big">
                                    <img loading="lazy" src="/images/Portfolio/EmbroideryDigitizing/AppliqueEmbroideryDigitizing/RBB-APPLIQUE.webp" alt="" style={{objectFit:'contain'}}/>
                                </div>
                                <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                    <Link to="/services/embroidery-digitizing/applique-embroidery-digitizing" className="project-item__link" aria-label='Navigate to Applique Embroidery Digitizing Page'><i
                                        className="far fa-arrow-right"></i></Link>
                                    <div className="project-item__content">
                                        {/* <h5 className="project-item__subtitle"><span>//</span> Book Print</h5> */}
                                        <h4 className="project-item__title">Applique Embroidery Digitizing</h4>
                                    </div>
                                </div>
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- project section end -->    */}
        </>
    )
}
