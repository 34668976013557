import React from 'react'
import Layout1 from '../layouts/Layout1'
import { Helmet } from 'react-helmet'
import VectorConversion from '../components/VectorConversion/VectorConversionComponent'

export default function VectorConversionPage() {

    return (
        <>

            <Layout1>
                <Helmet>

                    <title>Vector Conversion Services | Silk Digitizing</title>
                    <meta name="description" content="Convert raster images into high-quality vector art with Silk Digitizing's vector conversion services. Perfect for logos and designs." />
                    <meta name="keywords" content="vector conversion, vector art, vector design, Silk Digitizing, raster to vector, vectorization" />
                    <link rel="canonical" href="https://www.silkdigitizing.com/services/vector-conversion"></link>
                </Helmet>
                <VectorConversion/>
            </Layout1>
        </>
    )
}
